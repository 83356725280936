import "bootstrap";
import Vue from "vue";
import axios from "axios";
import App from "./App.vue";
import router from "./router";
import "@/assets/styles/main.scss";
import "@/assets/styles/dashboard.scss";
import store from "./store";
import VueSkeletonLoader from "skeleton-loader-vue";
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";

Vue.use(axios);
Vue.config.productionTip = false;
Vue.component("vue-skeleton-loader", VueSkeletonLoader);
Vue.component("v-select", vSelect);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
