import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";
import _ from "lodash";
import Swal from "sweetalert2";
const environment = "production"; // ENVIRONMENT, CHECK THIS CAREFULLY.

export let BASE_URL = "https://api.buku.kemdikbud.go.id/";
export let ASSESMENT_URL = "https://penilaian-buku.cloudapp.web.id";
export let SIBI_UPLOAD_URL = "https://upload.cloudapp.web.id/upload.php";
export let PENILAIAN_UPLOAD_URL ="https://upload-buku.cloudapp.web.id/upload.php";

if (environment == "production" || environment == "staging") {
  BASE_URL = "https://api.buku.kemdikbud.go.id/";
  ASSESMENT_URL = "https://penilaian-buku.cloudapp.web.id";
  PENILAIAN_UPLOAD_URL = "https://upload.cloudapp.web.id/upload-penilaian.php";
}

if (environment == "development" || environment == "local") {
  BASE_URL = "http://api.buku.kemdikbud.test/";
  ASSESMENT_URL = "https://penilaian-uji.cloudapp.web.id";
  PENILAIAN_UPLOAD_URL =  "https://upload-development.cloudapp.web.id/upload.php";
}

const d = new Date();
const today = d.toString();
const day = today.slice(0, 3);
const date = today.slice(8, 10);
const month = today.slice(4, 7);
const year = today.slice(11, 15);

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    googleClientId:
      "104944373110-hd7umobu1j3k66fnjm82l8gd32vnefm8.apps.googleusercontent.com",
    totalCatalogue: null,
    totalAssesment: null,
    totalRead: null,
    totalDownload: null,
    today: `${day}, ${date} ${month} ${year}`,
    books: [],
    subjects: [],
    booksPublisher: [],
    bookPublisher: [],
    bookProgress: [],
    bookSummary: [],
    eventPenilaian: [],
    portfolios: [],
    tasks: [],
    isbn: [],
    detailISBN: [],
    portfolio: [],
    bookDownload: [],
    assesments: [],
    trainings: [],
    certifications: [],
    policies: [],
    providers: [],
    policy: [],
    resultRender: {},
    perPage: 0,
    totalRows: 0,
    pagination: null,
    loadPage: false,
    loadProvider: false,
    loadSubmitAssesment: false,
    loadUploadFile: false,
    loadProcess: false,
    loadBookPublisher: false,
    loadEventPenilaian: false,
    messageStatus: false,
    messageStatusReport: false,
    messageStatusPortfolio: false,
    messageStatusISBN: false,
    message: "",
    messageStatusPublisher: false,
    messageStatusErrorPublisher: false,
    messageErrorPublisher: "",
    messageStatusAssesment: false,
    messageStatusErrorAssesment: false,
    messageErrorAssesment: "",
    msgcolor: "",
    messageRecovery: "",
    messageRecoveryError: "",

    user: [],
    role_name: JSON.parse(localStorage.getItem("user"))?.role_name || "",
    userProfile: null,
    activeMenu: "",
    token: localStorage.getItem("token") || "",

    image: null,

    loadPengajuan: false,
    loadPernyataan: false,
    loadKta: false,
    loadSiup: false,

    // State load penilaian
    loadFileOriginal: false,
    loadFileNoIdentity: false,
    loadFileCapaianPembelajaran: false,
    loadSuratPerjanjianPenerbitan: false,
    loadSuratPernyataanPenulis: false,
  },
  mutations: {
    setImage(state, image) {
      state.image = image;
    },
    setDataAssesment(state, payload) {
      state.perPage += payload.results.length;
      payload.results.forEach((el) => {
        state.assesments.push(el);
      });
    },
    setDataCertification(state, payload) {
      state.perPage += payload.results.length;
      payload.results.forEach((el) => {
        state.certifications.push(el);
      });
    },
    setDataUser(state, payload) {
      let user = {
        user_id: payload.user_id,
        fullname: payload.fullname,
        avatar: payload.avatar,
        role_name: payload.role_name,
        email: payload.email,
        hash: payload.hash,
      };
      localStorage.setItem("user", JSON.stringify(user));
      state.role_name = payload.role_name;
    },
    setToken(state, payload) {
      const token = payload;
      localStorage.setItem("token", token);
      state.token = token;
    },
    setUserProfile(state, payload) {
      state.userProfile = payload;
    },
    setDataPortfolio(state, payload) {
      state.portfolios = payload;
    },
    setDataTask(state, payload) {
      state.tasks = payload;
    },
    setDataISBN(state, payload) {
      state.isbn = payload;
    },
    setDetailISBN(state, payload) {
      state.detailISBN = payload;
    },
    setDetailPortfolio(state, payload) {
      state.portfolio = payload;
    },
    setBookDownload(state, payload) {
      state.bookDownload = payload;
    },
    setStatistic(state, payload) {
      state.totalCatalogue = payload.total_book;
      state.totalAssesment = payload.total_assessment;
      state.totalRead = payload.total_read;
      state.totalDownload = payload.total_download;
    },
    setDataPolicy(state, payload) {
      state.policy = payload;
    },
    setDataProvider(state, payload) {
      state.providers = payload;
    },
    setDataBooksPublisher(state, payload) {
      state.booksPublisher = payload;
    },
    setDataBookPublisher(state, payload) {
      state.bookPublisher = payload;
    },
    setDataBookProgress(state, payload) {
      state.bookProgress = payload;
    },
    setDataBookSummary(state, payload) {
      state.bookSummary = payload;
    },
    setDataEventPenilaian(state, payload) {
      state.eventPenilaian = payload;
    },
    setDataSubject(state, payload) {
      state.subjects = payload;
    },
  },
  actions: {
    logout({ state }) {
      state.user = [];
      state.token = "";
      state.role_name = "";
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    },
    login(context, payload) {
      context.state.loadPage = true;
      context.state.messageStatus = false;
      const endpoint = payload.id_token ? "sync" : "login";

      axios
        .post(`${BASE_URL}api/user/${endpoint}`, JSON.stringify(payload))
        .then((res) => {
          if (res.data.status == "failed") {
            context.state.messageStatus = true;
            context.state.message = res.data.message;
            context.state.msgColor = "alert alert-danger";
            context.state.loadPage = false;
            router.push("/login");
          } else {
            context.state.message = "";
            context.state.loadPage = false;
            context.commit("setDataUser", res.data.result);
            context.commit("setToken", res.data.token);
            router.push("/user/home");
          }
        })
        .catch(() => {
          context.state.loadPage = false;
        });
    },
    register(context, payload) {
      context.state.loadPage = true;
      axios
        .post(BASE_URL + "api/user/register", payload, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.status == "failed") {
            context.state.messageStatus = true;
            context.state.message = res.data.message;
            context.state.msgColor = "alert alert-danger";
            context.state.loadPage = false;
            router.push("/register");
          } else {
            context.state.messageStatus = true;
            context.state.message = `Berhasil mendaftarkan akun, selanjutnya silahkan cek email ${res.data.email} untuk aktivasi. Cek folder spam jika email tidak ada di inbox.`;
            context.state.msgColor = "alert alert-success";
            context.state.loadPage = false;
            router.push("/login");
          }
        });
    },
    fetchAllTraining(context) {
      context.state.loadPage = true;
      axios
        .get(
          BASE_URL +
            "api/entry/training_event?perpage=8&page=1&orderby=start&direction=desc"
        )
        .then((res) => {
          context.state.trainings = res.data.results;
          context.state.loadPage = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchDetailTraining(context, id) {
      context.state.loadPage = true;

      return new Promise((resolve, reject) => {
        axios
          .get(BASE_URL + "api/entry/training_event/detail/" + id)
          .then((res) => {
            resolve(res);
            context.commit("setDataPolicy", res.data);
            context.state.loadPage = false;
          })
          .catch((err) => reject(err));
      });
    },
    fetchAllCertification(context) {
      context.state.loadPage = true;
      axios
        .get(BASE_URL + "api/training/training_report/1?name=&role=")
        .then((res) => {
          context.state.certifications = res.data.results;
          context.state.totalRows = res.data.total_rows;
          context.state.perPage = res.data.per_page;
          context.state.pagination = res.data.pagination;
          context.state.loadPage = false;
        })
        .catch((err) => console.log(err));
    },
    filterCertification(context, payload) {
      context.state.loadPage = true;
      axios
        .get(
          BASE_URL +
            `api/training/training_report/0?name=${payload.name}&role=${payload.penelaah}`
        )
        .then((res) => {
          context.state.certifications = res.data.results;
          context.state.loadPage = false;
        })
        .catch((err) => console.log(err));
    },
    fetchLoadMoreCertification(context, amount) {
      context.state.loadPage = true;
      axios
        .get(BASE_URL + "api/training/training_report/" + amount)
        .then((res) => {
          context.commit("setDataCertification", res.data);
          context.state.loadPage = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchAllPolicy(context) {
      context.state.loadPage = true;
      axios
        .get("https://api.buku.kemdikbud.go.id/api/post?page=1&perpage=300")
        .then((res) => {
          context.state.policies = res.data.posts;
          context.state.loadPage = false;
        })
        .catch((err) => console.log(err));
    },
    fetchUserProfile(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(BASE_URL + "api/user/getProfile", {
            headers: {
              Authorization: context.state.token,
            },
          })
          .then((res) => {
            context.commit("setUserProfile", res.data.result);
            resolve(res.data.result);
          })
          .catch((err) => reject(err.response));
      });
    },
    updateUserProfile(context, payload) {
      context.state.loadPage = true;
      var body = new FormData();
      body.append("name", payload.name);
      body.append("username", payload.username);
      body.append("email", payload.email);
      body.append("avatar", payload.avatar);
      body.append("password", payload.password);
      body.append("confirm_password", payload.confirm_password);
      body.append("profile", payload.profile);
      axios({
        method: "post",
        url: BASE_URL + "api/user/updateProfilePassword",
        data: body,
        headers: {
          Authorization: context.state.token,
          "Content-type": "multipart/form-data",
        },
      })
        .then(() => {
          context.state.messageStatus = true;
          context.state.loadPage = false;
        })
        .catch((err) => {
          context.state.loadPage = false;
          console.log(err);
        });
    },
    updatePublisherProfile(context, payload) {
      // Function for rename keys in object
      function renameKeys(obj, newKeys) {
        const keyValues = Object.keys(obj).map((key) => {
          const newKey = newKeys[key] || key;
          return { [newKey]: obj[key] };
        });
        return Object.assign({}, ...keyValues);
      }

      const newKeys = {
        surat_pengajuan: "surat_perjanjian_penerbitan",
        surat_pernyataan: "surat_perjanjian_hak_cipta",
        kta_ikapi: "sertifikat_bnsp",
      };

      const data = renameKeys(payload, newKeys);
      context.state.loadPage = true;

      axios({
        method: "post",
        url: BASE_URL + "api/user/updatePublisherProfile",
        data: data,
        headers: {
          Authorization: context.state.token,
          "content-type": "application/json",
        },
      })
        .then((res) => {
          if (res.data.status == "failed") {
            context.state.messageStatusErrorPublisher = true;
            context.state.messageErrorPublisher = res.data.message;
          } else {
            context.state.messageStatusPublisher = true;
            context.state.loadPage = false;
          }
        })
        .catch((err) => {
          context.state.loadPage = false;
          console.log(err);
        });
    },
    submitAssesment(context, payload) {
      context.state.loadSubmitAssesment = true;

      const data = JSON.stringify(_.omit(payload, ["type_request", "id"]));
      const endpoint =
        payload.type_request == "post"
          ? "/api/penilaian/book/submit"
          : `/api/penilaian/book/update/${payload.id}`;

      return new Promise((resolve, reject) => {
        axios({
          method: payload.type_request,
          url: ASSESMENT_URL + endpoint,
          data: data,
          headers: {
            Authorization: context.state.token,
            "content-type": "application/json",
          },
        })
          .then((res) => {
            if (res.data.status == "failed") {
              context.state.messageStatusErrorAssesment = true;
              context.state.messageErrorAssesment = res.data.message;
            } else {
              context.state.messageStatusErrorAssesment = false;
              context.state.messageErrorAssesment = "";
              context.state.messageStatusAssesment = true;
              context.state.loadSubmitAssesment = false;
            }
            resolve(res);
            Toast.fire({
              icon: res.data.status == "success" ? "success" : "error",
              title: res.data.message,
            });
          })
          .catch((err) => {
            context.state.loadSubmitAssesment = false;
            reject(err);
            Toast.fire({
              icon: "error",
              title: err.response.data.messsage,
            });
          })
          .finally(() => {
            context.state.loadFileOriginal = false;
            context.state.loadFileNoIdentity = false;
            context.state.loadFileCapaianPembelajaran = false;
            context.state.loadSuratPerjanjianPenerbitan = false;
            context.state.loadSuratPernyataanPenulis = false;
          });
      });
    },
    fetchAllBook(context) {
      axios
        .get(BASE_URL + "api/catalogue/getLatest?qty=100")
        .then((res) => {
          context.state.books = res.data.results;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sendReportBook(context, payload) {
      context.state.loadPage = true;
      context.state.messageStatusReport = false;
      return new Promise((resolve, reject) => {
        axios({
          url: BASE_URL + "api/report/addReport",
          method: "POST",
          data: payload,
          headers: {
            Authorization: context.state.token,
          },
        })
          .then((res) => {
            context.state.loadPage = false;
            context.state.messageStatusReport = true;
            resolve(res);
          })
          .catch((err) => {
            context.state.loadPage = false;
            console.log(err);
            reject(err);
          });
      });
    },
    sendPrintPermission(context, type) {
      context.state.loadPage = true;

      const payload = {
        print_permission: {
          cover: "yes",
          identity: "yes",
          content: "yes",
        },
      };

      return new Promise((resolve, reject) => {
        axios({
          url: BASE_URL + "api/user/updatePrintPermission/" + type,
          method: "POST",
          data: JSON.stringify(payload),
          headers: {
            Authorization: context.state.token,
          },
        })
          .then((res) => {
            resolve(res);
            context.state.loadPage = false;
          })
          .catch((err) => {
            context.state.loadPage = false;
            reject(err);
          });
      });
    },
    fetchBookDownload(context) {
      context.state.loadPage = true;
      axios
        .get(BASE_URL + "/api/catalogue")
        .then((res) => {
          context.state.bookDownload = res.data.results;
          context.state.loadPage = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addNewPortfolio(context, payload) {
      context.state.loadPage = true;
      var body = new FormData();
      body.append("title", payload.title);
      body.append("type", payload.type);
      body.append("description", payload.description);
      body.append("attachment", payload.attachment);
      body.append("year", payload.year);
      // body.append("link", payload.link);

      return new Promise((resolve, reject) => {
        axios({
          url: BASE_URL + "api/portfolio/insert",
          method: "POST",
          data: body,
          headers: {
            Authorization: context.state.token,
            "Content-type": "multipart/form-data",
          },
        })
          .then((res) => {
            context.state.messageStatusPortfolio = true;
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            context.state.loadPage = false;
          });
      });
    },
    fetchAllPortfolio(context) {
      context.state.loadPage = true;
      axios({
        url: BASE_URL + "api/portfolio?page=1",
        method: "GET",
        headers: {
          Authorization: context.state.token,
        },
      })
        .then((res) => {
          context.commit("setDataPortfolio", res.data);
          context.state.loadPage = false;
        })
        .catch((err) => console.log(err));
    },
    fetchAllTask(context) {
      context.state.loadPage = true;
      axios({
        url: BASE_URL + "api/portfolio?page=1&type=Tugas",
        method: "GET",
        headers: {
          Authorization: context.state.token,
        },
      })
        .then((res) => {
          context.commit("setDataTask", res.data);
          context.state.loadPage = false;
        })
        .catch((err) => console.log(err));
    },
    deletePortfolioById(context, id) {
      context.state.loadPage = true;
      axios
        .get(BASE_URL + "api/portfolio/delete/" + id, {
          headers: {
            Authorization: context.state.token,
          },
        })
        .then(() => {
          context.dispatch("fetchAllPortfolio");
          setTimeout(() => {
            context.state.loadPage = false;
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchDetailPortfolio(context, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(BASE_URL + "api/portfolio/detail/" + id, {
            headers: {
              Authorization: context.state.token,
            },
          })
          .then((res) => {
            context.commit("setDetailPortfolio", res.data);
            resolve(res.data.result);
          })
          .catch((err) => reject(err.response));
      });
    },
    updateDetailPortfolio(context, payload) {
      context.state.loadPage = true;
      var body = new FormData();
      body.append("id", payload.id);
      body.append("title", payload.title);
      body.append("type", payload.type);
      body.append("description", payload.description);
      body.append("attachment", payload.attachment);
      body.append("year", payload.year);
      // body.append("link", payload.link);

      return new Promise((resolve, rejected) => {
        axios({
          method: "post",
          url: BASE_URL + "api/portfolio/update",
          data: body,
          headers: {
            Authorization: context.state.token,
            "Content-type": "multipart/form-data",
          },
        })
          .then((res) => {
            resolve(res);
            context.state.messageStatus = true;
          })
          .catch((err) => {
            rejected(err);
          })
          .finally(() => {
            context.state.loadPage = false;
          });
      });
    },
    filterSearchBook(context, payload) {
      context.state.loadPage = true;
      axios({
        url:
          BASE_URL +
          `api/catalogue?filter[catalogue][title]=${payload.title}&filter[catalogue][writer]=${payload.writer}&filter[catalogue][published_year]=${payload.year}&filter[catalogue][isbn]=${payload.isbn}`,
        method: "GET",
      })
        .then((res) => {
          context.state.loadPage = false;
          context.commit("setBookDownload", res.data.results);
        })
        .catch((err) => {
          console.log(err);
          context.state.loadPage = false;
        });
    },
    fetchTotalCatalogue(context) {
      axios
        .get(BASE_URL + "api/statistic/getSummary")
        .then((res) => {
          context.commit("setStatistic", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    uploadGoogleDrive(context, image) {
      return new Promise((resolve, reject) => {
        var reader = new FileReader(); //this for convert to Base64
        reader.readAsDataURL(image); //start conversion...
        reader.onload = async () => {
          //.. once finished..
          var rawLog = reader.result.split(",")[1]; //extract only thee file data part
          var dataSend = {
            dataReq: { data: rawLog, name: image.name, type: image.type },
            fname: "uploadFilesToGoogleDrive",
          }; //prepare info to send to API
          try {
            let response = await axios.post(
              "https://script.google.com/macros/s/AKfycbymVZP0chABKXnaK6lammJfooUuXgx7UR8QKbRf3d3BNnVCwspwIemYlQrzdM9laPOL/exec",
              JSON.stringify(dataSend)
            );
            resolve(response);
          } catch (error) {
            reject(error);
          }
        };
      });
    },
    uploadImage(context, image) {
      context.state.loadUploadFile = true;
      const formData = new FormData();
      formData.append("image", image);

      return new Promise((resolve, reject) => {
        axios
          .post(
            "https://api.imgbb.com/1/upload?key=0622add0e6f04d563c9eb6ba6c3f40c0",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((res) => {
            resolve(res.data);
            context.commit("setImage", res.data.data.image.url);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            context.state.loadUploadFile = false;
          });
      });
    },
    uploadFile(context, file) {
      context.state.loadUploadFile = true;
      const formData = new FormData();
      formData.append("file", file);

      return new Promise((resolve, reject) => {
        axios
          .post(SIBI_UPLOAD_URL, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            console.log(err);
            reject(403);
          })
          .finally(() => {
            context.state.loadUploadFile = false;
          });
      });
    },
    uploadFileAssesment(context, payload) {
      context.state.loadUploadFile = true;
      const formData = new FormData();
      formData.append("file", payload.file);
      formData.append("event_id", payload.eventId);
      formData.append("field", payload.field);

      return new Promise((resolve, reject) => {
        axios
          .post(PENILAIAN_UPLOAD_URL, formData, {
            onUploadProgress: payload.onUploadProgress,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            console.log(err);
            reject(403);
          })
          .finally(() => {
            context.state.loadUploadFile = false;
          });
      });
    },
    uploadFilePDF(context, file) {
      context.state.loadUploadFile = true;
      const formData = new FormData();
      formData.append("image", file);

      return new Promise((resolve, reject) => {
        axios
          .post(
            "https://api.imgbb.com/1/upload?key=0622add0e6f04d563c9eb6ba6c3f40c0",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((res) => {
            resolve(res.data.data);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            context.state.loadUploadFile = false;
          });
      });
    },
    recoveryAccount(context, email) {
      context.state.loadPage = true;
      context.state.messageRecovery = "";
      context.state.messageRecoveryError = "";

      const body = new FormData();
      body.append("email", email);
      body.append("source", "platform");

      axios({
        method: "POST",
        url: BASE_URL + "api/user/reset_password",
        data: body,
        headers: {
          "Content-type": "multipart/form-data",
        },
      })
        .then((res) => {
          context.state.messageRecovery = res.data.message;
        })
        .catch((err) => {
          console.log(err);
          context.state.messageRecoveryError = "Email tidak ditemukan";
        })
        .finally(() => (context.state.loadPage = false));
    },
    registerTraining(context, payload) {
      context.state.loadProcess = true;

      const body = new FormData();
      body.append("training_id", payload.training_id);
      body.append("user_id", payload.user_id);
      body.append("name", payload.name);
      body.append("email", payload.email);
      body.append("phone", payload.phone);
      body.append("address", payload.address);
      body.append("role", payload.role);
      body.append("session", payload.session);
      body.append("note", payload.note);
      body.append("role_detail", payload.role_detail);
      body.append("difable_status", payload.difable_status);
      body.append("studentmeta", payload.studentMeta);
      body.append("theme", payload.theme);

      return new Promise((resolve, reject) => {
        axios({
          method: "POST",
          url: BASE_URL + "api/entry/training_student",
          data: body,
          headers: {
            "Content-type": "multipart/form-data",
          },
        })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => (context.state.loadProcess = false));
      });
    },
    fetchRegisterTraining(context, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${BASE_URL}api/entry/training_student/detailByField?user_id=${data.user_id}&training_id=${data.training_id}`
          )
          .then((res) => {
            resolve(res);
          })
          .catch((err) => reject(err));
      });
    },
    fetchAllProvider(context, type) {
      context.state.loadProvider = true;
      axios
        .get(`${BASE_URL}publishers/permission/${type}`)
        .then((res) => {
          context.commit("setDataProvider", res.data);
          context.state.loadProvider = false;
        })
        .catch((err) => console.log(err));
    },
    fetchBooksByPublisher(context, payload) {
      context.state.loadBookPublisher = true;
      return new Promise((resolve, reject) => {
        axios
          .get(`${ASSESMENT_URL}/api/penilaian/book`, {
            params: {
              ...payload,
            },
            headers: {
              Authorization: context.state.token,
            },
          })
          .then((res) => {
            context.commit("setDataBooksPublisher", res.data);
            context.state.loadBookPublisher = false;
            resolve(res);
          })
          .catch((err) => reject(err));
      });
    },
    fetchBookByPublisher(context, id) {
      context.state.loadBookPublisher = true;
      return new Promise((resolve, reject) => {
        axios
          .get(`${ASSESMENT_URL}/api/penilaian/book/detail/${id}`, {
            headers: {
              Authorization: context.state.token,
            },
          })
          .then((res) => {
            context.commit("setDataBookPublisher", res.data);
            context.state.loadBookPublisher = false;
            resolve(res);
          })
          .catch((err) => reject(err));
      });
    },
    deleteBookByPublisher(context, id) {
      context.state.loadBookPublisher = true;
      return new Promise((resolve, reject) => {
        axios
          .delete(`${ASSESMENT_URL}/api/penilaian/book/delete/${id}`)
          .then((res) => {
            context.state.loadBookPublisher = false;
            context.dispatch("fetchBooksByPublisher");
            resolve(res);
          })
          .catch((err) => reject(err));
      });
    },
    setStatusBook(context, payload) {
      context.state.loadBookPublisher = true;

      axios
        .patch(
          `${ASSESMENT_URL}/api/penilaian/book/updateStatus/${payload.id}/${payload.status}`,
          {},
          {
            headers: {
              Authorization: context.state.token,
            },
          }
        )
        .then((res) => {
          context.state.loadBookPublisher = false;
          Toast.fire({
            icon: res.data.status == "success" ? "success" : "error",
            title: res.data.message,
          });
        })
        .catch(
          () =>
            (context.state.messageStatusError = `Failed set ${payload.status}`)
        );
    },
    fetchBookProgress(context, id) {
      context.state.loadBookPublisher = true;
      axios
        .get(`${ASSESMENT_URL}/api/penilaian/book/progress/${id}`)
        .then((res) => {
          context.commit("setDataBookProgress", res.data);
          context.state.loadBookPublisher = false;
        })
        .catch((err) => console.log(err));
    },
    fetchBookSummary(context, id) {
      context.state.loadBookPublisher = true;
      axios
        .get(`${ASSESMENT_URL}/api/penilaian/book/summary/${id}`, {
          headers: {
            Authorization: context.state.token,
          },
        })
        .then((res) => {
          context.commit("setDataBookSummary", res.data);
          context.state.loadBookPublisher = false;
        })
        .catch((err) => console.log(err));
    },
    fetchAllEventPenilaian(context) {
      context.state.loadEventPenilaian = true;
      return new Promise((resolve, reject) => {
        axios
          .get(`${ASSESMENT_URL}/api/penilaian/events`)
          .then((res) => {
            context.commit("setDataEventPenilaian", res.data);
            context.state.loadEventPenilaian = false;
            resolve(res);
          })
          .catch((err) => reject(err));
      });
    },
    fetchAllSubject(context) {
      axios
        .get(`${BASE_URL}/api/entry/het_subject?perpage=400`)
        .then((res) => {
          context.commit("setDataSubject", res.data.results);
        })
        .catch((err) => console.log(err));
    },
    fetchPublisherProfile(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${BASE_URL}api/user/getPublisherProfile`, {
            headers: {
              Authorization: context.state.token,
            },
          })
          .then((res) => resolve(res.data.result))
          .catch((err) => reject(err));
      });
    },
    fetchAllISBN(context) {
      context.state.loadPage = true;
      axios({
        url: BASE_URL + "api/isbn",
        method: "GET",
        headers: {
          Authorization: context.state.token,
        },
      })
        .then((res) => {
          if (res.data[0] != false) {
            context.commit("setDataISBN", res.data);
          }
          context.state.loadPage = false;
        })
        .catch((err) => console.log(err));
    },
    addNewISBN(context, payload) {
      context.state.loadPage = true;
      var body = new FormData();

      body.append("judul", payload.judul);
      body.append("kepengarangan", payload.kepengarangan);
      body.append("sinopsis", payload.sinopsis);
      body.append("distributor", payload.distributor);
      body.append("edisi", payload.edisi);
      body.append("seri", payload.seri);
      body.append("cover", payload.cover);
      body.append("tahun_terbit", payload.tahun_terbit);
      body.append("jumlah_halaman", payload.jumlah_halaman);
      body.append("tinggi_buku", payload.tinggi_buku);
      body.append("dimensi", payload.dimensi);
      body.append("harga_buku", payload.harga_buku);
      body.append("bulan_terbit", payload.bulan_terbit);
      body.append("tempat_terbit", payload.tempat_terbit);
      body.append("kelompok_pembaca", payload.kelompok_pembaca);
      body.append("jenis_pustaka", payload.jenis_pustaka);
      body.append("kategori_jenis", payload.kategori_jenis);
      body.append("terbitan", payload.terbitan);
      body.append("media", payload.media);
      body.append("isbn_susulan", payload.isbn_susulan);
      body.append("pengajuan_kdt", payload.pengajuan_kdt);
      body.append("penelitian", payload.penelitian);
      body.append("kategori_buku", payload.kategori_buku);
      body.append("lampiran", payload.lampiran);
      body.append("prelim", payload.prelim);
      body.append("dummy", payload.dummy);

      return new Promise((resolve, reject) => {
        axios({
          url: BASE_URL + "api/isbn/insert",
          method: "POST",
          data: body,
          headers: {
            Authorization: context.state.token,
            "Content-type": "multipart/form-data",
          },
        })
          .then((res) => {
            context.state.messageStatusISBN = true;
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            context.state.loadPage = false;
          });
      });
    },
    deleteISBNById(context, id) {
      context.state.loadPage = true;
      axios
        .delete(BASE_URL + "api/isbn/delete/" + id, {
          headers: {
            Authorization: context.state.token,
          },
        })
        .then(() => {
          context.dispatch("fetchAllISBN");
          setTimeout(() => {
            context.state.loadPage = false;
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchDetailISBN(context, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(BASE_URL + "api/isbn/detail/" + id, {
            headers: {
              Authorization: context.state.token,
            },
          })
          .then((res) => {
            context.commit("setDetailISBN", res.data);
            resolve(res.data.result);
          })
          .catch((err) => reject(err.response));
      });
    },
    updateDetailISBN(context, payload) {
      var body = new FormData();

      body.append("id", payload.id);
      body.append("judul", payload.judul);
      body.append("kepengarangan", payload.kepengarangan);
      body.append("sinopsis", payload.sinopsis);
      body.append("distributor", payload.distributor);
      body.append("edisi", payload.edisi);
      body.append("seri", payload.seri);
      body.append("cover", payload.cover);
      body.append("tahun_terbit", payload.tahun_terbit);
      body.append("jumlah_halaman", payload.jumlah_halaman);
      body.append("tinggi_buku", payload.tinggi_buku);
      body.append("dimensi", payload.dimensi);
      body.append("harga_buku", payload.harga_buku);
      body.append("bulan_terbit", payload.bulan_terbit);
      body.append("tempat_terbit", payload.tempat_terbit);
      body.append("kelompok_pembaca", payload.kelompok_pembaca);
      body.append("jenis_pustaka", payload.jenis_pustaka);
      body.append("kategori_jenis", payload.kategori_jenis);
      body.append("terbitan", payload.terbitan);
      body.append("media", payload.media);
      body.append("isbn_susulan", payload.isbn_susulan);
      body.append("pengajuan_kdt", payload.pengajuan_kdt);
      body.append("penelitian", payload.penelitian);
      body.append("kategori_buku", payload.kategori_buku);
      body.append("lampiran", payload.lampiran);
      body.append("prelim", payload.prelim);
      body.append("dummy", payload.dummy);

      return new Promise((resolve, rejected) => {
        axios({
          method: "post",
          url: BASE_URL + "api/isbn/update",
          data: body,
          headers: {
            Authorization: context.state.token,
            "Content-type": "multipart/form-data",
          },
        })
          .then((res) => {
            resolve(res);
            context.state.messageStatus = true;
          })
          .catch((err) => {
            rejected(err);
          })
          .finally(() => {
            context.state.loadPage = false;
          });
      });
    },
    updateBuktiTerima(context, payload) {
      var body = new FormData();

      body.append("id", payload.id);
      body.append("bukti_terima", payload.bukti_terima);

      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      return new Promise((resolve, rejected) => {
        axios({
          method: "post",
          url: BASE_URL + "api/isbn/update",
          data: body,
          headers: {
            Authorization: context.state.token,
            "Content-type": "multipart/form-data",
          },
        })
          .then((res) => {
            resolve(res);
            context.state.messageStatus = true;
            Toast.fire({
              icon: res.data.status == "success" ? "success" : "error",
              title: res.data.message,
            });
          })
          .catch((err) => {
            rejected(err);
          })
          .finally(() => {
            context.state.loadPage = false;
          });
      });
    },
    fetchTodolistByBook(context, book_id) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${ASSESMENT_URL}/api/penilaian/todolist/getTodolistByBook?book_id=${book_id}`,
            {
              headers: {
                Authorization: context.state.token,
              },
            }
          )
          .then((response) => resolve(response))
          .catch((err) => reject(err));
      });
    },
    fetchDetailFeedback(context, feedback_id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${ASSESMENT_URL}/api/penilaian/todolist/getDetailFeedback?id=${feedback_id}`, {
            headers: {
              Authorization: context.state.token,
            },
          })
          .then(response => resolve(response))
          .catch(err => reject(err));
      })
    },
    sendFeedback(context, payload) {
      context.state.loadProcess = true
    
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      let method = payload.todolist_id ? 'POST' : 'PUT';
      let endpoint = payload.todolist_id ? 'sendFeedback' : 'updateFeedback';
      
      return new Promise((resolve, reject) => {
        axios({
          method: method,
          url: ASSESMENT_URL + `/api/penilaian/todolist/${endpoint}`,
          data: JSON.stringify(payload),
          headers: {
            Authorization: context.state.token
          },
        })
        .then(response => {
          Toast.fire({
            icon: response.data.status == 'success' ? 'success' : 'error',
            title: response.data.message
          })
          resolve(response)
        })
        .catch(err => reject(err))
        .finally(() => context.state.loadProcess = false)
      })

    },
    fetchResultTurnitin(context, book_id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${ASSESMENT_URL}/api/penilaian/book/getTurnitin/${book_id}`, {
            headers: {
              Authorization: context.state.token,
            },
            responseType: 'blob'
          })
          .then(response => resolve(response))
          .catch(err => reject(err));
      })
    },
  },
  modules: {},
});
